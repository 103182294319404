import React, { useState, useEffect, useContext } from 'react';
import { firestore } from './firebase';
import { collection, doc, setDoc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { CurrentYearContext } from './CurrentYearContext';

const Settings = () => {
  const { currentYear, updateCurrentYear } = useContext(CurrentYearContext);
  const [newYear, setNewYear] = useState('');
  const [availableYears, setAvailableYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [sourceCollection, setSourceCollection] = useState('');
  const [targetCollection, setTargetCollection] = useState('');
  const [lightDarkMode, setLightDarkMode] = useState('system');

  useEffect(() => {
    const fetchYearsAndSettings = async () => {
      const yearsSnapshot = await getDocs(
        collection(doc(firestore, 'settings', 'settings'), 'years')
      );
      setAvailableYears(yearsSnapshot.docs.map((doc) => doc.id));

      const settingsDoc = await getDoc(doc(firestore, 'settings', 'settings'));
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        setLightDarkMode(data.light_dark_mode || 'system');
      }
    };

    fetchYearsAndSettings();
  }, []);

  useEffect(() => {
    if (currentYear) {
      setSelectedYear(currentYear);
    }
  }, [currentYear]);

  const handleCreateYear = async () => {
    if (newYear) {
      const months = [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
      ];
      const defaultMonthData = {
        current_balance: 0,
        forecast_balance: 0,
        opening_balance: 0,
        cash_budget: 0,
        cash_notes: '',
      };

      try {
        await Promise.all(
          months.map(async (month) => {
            const monthDoc = doc(firestore, `${newYear}`, `${month}`);
            await setDoc(monthDoc, defaultMonthData);

            // Create sub-collections
            const subCollections = [
              'fixed_expenses',
              'fixed_income',
              'flexible_expenses',
              'flexible_income',
            ];
            await Promise.all(
              subCollections.map(async (subCollection) => {
                const subCollectionRef = collection(
                  firestore,
                  `${newYear}/${month}/${subCollection}`
                );
                // Adding a dummy document to ensure the sub-collection is created
                await setDoc(doc(subCollectionRef, 'dummy'), {
                  initialized: true,
                });
              })
            );
          })
        );

        // Add the new year to the years subcollection
        const settingsRef = doc(firestore, 'settings', 'settings');
        await setDoc(doc(settingsRef, 'years', newYear), {});

        setAvailableYears([...availableYears, newYear]);
        setNewYear('');
        alert(`Year ${newYear} created successfully.`);
      } catch (error) {
        console.error('Error creating new year: ', error);
        alert(`Failed to create year ${newYear}.`);
      }
    }
  };

  const handleSelectYear = async (e) => {
    e.preventDefault();

    if (selectedYear) {
      try {
        await updateCurrentYear(selectedYear);
      } catch (error) {
        console.error('Error setting current year: ', error);
        alert(`Failed to set year ${selectedYear}.`);
      }
    }
  };

  const handleCloneCollection = async () => {
    if (!sourceCollection || !targetCollection) {
      alert('Please specify both source and target collections.');
      return;
    }

    try {
      const cloneCollection = async (sourcePath, targetPath) => {
        const sourceSnapshot = await getDocs(collection(firestore, sourcePath));
        await Promise.all(
          sourceSnapshot.docs.map(async (docSnapshot) => {
            const docData = docSnapshot.data();
            const targetDocRef = doc(
              firestore,
              `${targetPath}/${docSnapshot.id}`
            );
            await setDoc(targetDocRef, docData);

            // Recursively clone sub-collections
            const subCollections = [
              'fixed_expenses',
              'fixed_income',
              'flexible_expenses',
              'flexible_income',
            ];
            await Promise.all(
              subCollections.map(async (subCollection) => {
                const sourceSubCollectionPath = `${sourcePath}/${docSnapshot.id}/${subCollection}`;
                const targetSubCollectionPath = `${targetPath}/${docSnapshot.id}/${subCollection}`;
                await cloneCollection(
                  sourceSubCollectionPath,
                  targetSubCollectionPath
                );
              })
            );
          })
        );
      };

      await cloneCollection(sourceCollection, targetCollection);
      alert(
        `Collection ${sourceCollection} cloned to ${targetCollection} successfully.`
      );
    } catch (error) {
      console.error('Error cloning collection: ', error);
      alert(`Failed to clone collection: ${error.message}`);
    }
  };

  const handleLightDarkModeChange = async (e) => {
    const selectedMode = e.target.value;
    setLightDarkMode(selectedMode);
    const settingsRef = doc(firestore, 'settings', 'settings');
    await updateDoc(settingsRef, {
      light_dark_mode: selectedMode,
    });

    if (selectedMode === 'light') {
      document.documentElement.classList.remove('dark');
      localStorage.theme = 'light';
    } else if (selectedMode === 'dark') {
      document.documentElement.classList.add('dark');
      localStorage.theme = 'dark';
    } else {
      localStorage.removeItem('theme');
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  };

  return (
    <div className="p-2">
      <div className="mb-4 pb-6 border-b">
        <h2 className="text-lg font-semibold mb-2 text-slate-600 dark:text-slate-400">
          Set Current Year
        </h2>
        <form onSubmit={handleSelectYear}>
          <div className="flex justify-between space-x-2">
            <select
              id="selectYear"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              className="p-2 border border-gray-300 rounded w-1/2 dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
            >
              <option value="">Select year</option>
              {availableYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            <button
              type="submit"
              className="bg-lime-600 text-white px-4 py-2 rounded w-1/2"
            >
              Set
            </button>
          </div>
        </form>
      </div>
      <div className="mb-4 pb-6 border-b">
        <h2 className="text-lg font-semibold mb-2 text-slate-600 dark:text-slate-400">
          Create New Year
        </h2>
        <div className="flex justify-between space-x-2">
          <input
            id="newYear"
            type="number"
            value={newYear}
            onChange={(e) => setNewYear(e.target.value)}
            placeholder="Enter year eg. 2025"
            className="p-2 border border-gray-300 rounded mr-2 w-1/2 dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
          />
          <button
            onClick={handleCreateYear}
            className="bg-lime-600 text-white px-4 py-2 rounded w-1/2"
          >
            Create
          </button>
        </div>
      </div>
      <div className="mb-4 pb-6 border-b">
        <h2 className="text-lg font-semibold mb-2 text-slate-600 dark:text-slate-400">
          Light/Dark Mode
        </h2>
        <div className="flex justify-between space-x-2">
          <select
            id="lightDarkMode"
            value={lightDarkMode}
            onChange={handleLightDarkModeChange}
            className="p-2 border border-gray-300 rounded w-full dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
          >
            <option value="system">System</option>
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </select>
        </div>
      </div>
      <div className="mb-4">
        <h2 className="text-lg font-semibold mb-2 text-slate-600 dark:text-slate-400">
          Clone Year
        </h2>
        <div className="flex justify-between space-x-2">
          <input
            id="sourceCollection"
            type="text"
            value={sourceCollection}
            onChange={(e) => setSourceCollection(e.target.value)}
            placeholder="Source year"
            className="p-2 border border-gray-300 rounded mb-2 w-1/2 dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
          />
          <input
            id="targetCollection"
            type="text"
            value={targetCollection}
            onChange={(e) => setTargetCollection(e.target.value)}
            placeholder="Target year"
            className="p-2 border border-gray-300 rounded mb-2 w-1/2 dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
          />
        </div>

        <button
          onClick={handleCloneCollection}
          className="bg-lime-600 text-white px-4 py-2 rounded w-full"
        >
          Clone Year
        </button>
      </div>
    </div>
  );
};

export default Settings;
