import React, { useState, useEffect, useContext, useCallback } from 'react';
import { firestore } from './firebase';
import {
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  getDoc,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { CurrentYearContext } from './CurrentYearContext';

const monthOrder = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

const fullMonthNames = {
  jan: 'January',
  feb: 'February',
  mar: 'March',
  apr: 'April',
  may: 'May',
  jun: 'June',
  jul: 'July',
  aug: 'August',
  sep: 'September',
  oct: 'October',
  nov: 'November',
  dec: 'December',
};

const defaultMonthData = {
  current_balance: 0,
  forecast_balance: 0,
  opening_balance: 0,
  cash_budget: 0, // Add default cash budget field
};

const Current = () => {
  const [months, setMonths] = useState([]);
  const [editableRow, setEditableRow] = useState(null);
  const [editMonth, setEditMonth] = useState(null);
  const [newFlexibleExpense, setNewFlexibleExpense] = useState({
    amount: '',
    description: '',
    category: 'Cash',
    hide: false,
    current: true,
  });
  const [newFlexibleIncome, setNewFlexibleIncome] = useState({
    amount: '',
    description: '',
    category: 'Misc',
    hide: false,
    current: true,
  });
  const [cashBudget, setCashBudget] = useState({});
  const [cashNotes, setCashNotes] = useState({});
  const [cashEditMode, setCashEditMode] = useState({});
  const [showAddIncomeForm, setShowAddIncomeForm] = useState(false);
  const [showAddExpenseForm, setShowAddExpenseForm] = useState(false);
  const [showPreviousMonths, setShowPreviousMonths] = useState(false);
  const [categoryVisibility, setCategoryVisibility] = useState({});
  const [showTransactions, setShowTransactions] = useState(false);

  const { currentYear } = useContext(CurrentYearContext);

  const currentMonthIndex = new Date().getMonth();

  const rebuildDatabaseStructure = useCallback(async () => {
    try {
      await Promise.all(
        monthOrder.map(async (month) => {
          const monthDoc = doc(firestore, `${currentYear}/${month}`);
          await setDoc(monthDoc, defaultMonthData);
        })
      );
      console.log('Database structure rebuilt without example documents.');
    } catch (error) {
      console.error('Error rebuilding database structure: ', error);
    }
  }, [currentYear]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data...');
        const monthsCollection = collection(firestore, currentYear);
        const monthsSnapshot = await getDocs(monthsCollection);

        const settingsDocRef = doc(firestore, 'settings', 'settings');
        const settingsDoc = await getDoc(settingsDocRef);
        if (settingsDoc.exists()) {
          const settingsData = settingsDoc.data();
          setShowPreviousMonths(settingsData.show_all_months);
          setShowTransactions(settingsData.show_transactions);
        }

        if (monthsSnapshot.empty) {
          console.log(
            `No documents found in '${currentYear}' collection. Rebuilding structure...`
          );
          await rebuildDatabaseStructure();
        } else {
          console.log(
            'Months Snapshot:',
            monthsSnapshot.docs.map((doc) => doc.id)
          );

          const fetchedMonths = await Promise.all(
            monthsSnapshot.docs.map(async (doc) => {
              const fixedIncomeSnapshot = await getDocs(
                collection(firestore, `${currentYear}/${doc.id}/fixed_income`)
              );
              const fixedExpensesSnapshot = await getDocs(
                collection(firestore, `${currentYear}/${doc.id}/fixed_expenses`)
              );
              const flexibleIncomeSnapshot = await getDocs(
                collection(
                  firestore,
                  `${currentYear}/${doc.id}/flexible_income`
                )
              );
              const flexibleExpensesSnapshot = await getDocs(
                collection(
                  firestore,
                  `${currentYear}/${doc.id}/flexible_expenses`
                )
              );

              return {
                id: doc.id,
                ...doc.data(),
                fixed_income: fixedIncomeSnapshot.docs.map((d) => ({
                  id: d.id,
                  ...d.data(),
                })),
                fixed_expenses: fixedExpensesSnapshot.docs.map((d) => ({
                  id: d.id,
                  ...d.data(),
                })),
                flexible_income: flexibleIncomeSnapshot.docs.map((d) => ({
                  id: d.id,
                  ...d.data(),
                })),
                flexible_expenses: flexibleExpensesSnapshot.docs.map((d) => ({
                  id: d.id,
                  ...d.data(),
                })),
              };
            })
          );

          setCashBudget(
            fetchedMonths.reduce((acc, month) => {
              acc[month.id] = month.cash_budget || 0;
              return acc;
            }, {})
          );

          setCashNotes(
            fetchedMonths.reduce((acc, month) => {
              acc[month.id] = month.cash_notes || '';
              return acc;
            }, {})
          );

          // Sort months in chronological order
          fetchedMonths.sort(
            (a, b) => monthOrder.indexOf(a.id) - monthOrder.indexOf(b.id)
          );

          setCashEditMode(
            fetchedMonths.reduce((acc, month) => {
              acc[month.id] = false;
              return acc;
            }, {})
          );

          // Calculate balances
          calculateBalances(fetchedMonths, currentYear);

          setMonths(fetchedMonths);
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    if (currentYear) {
      fetchData();
    }
  }, [currentYear, rebuildDatabaseStructure]);

  const calculateBalances = (fetchedMonths, year) => {
    const currentYear = new Date().getFullYear().toString();
    const currentMonthIndex = new Date().getMonth();
    let previousForecastBalance = 0;

    fetchedMonths.forEach((month, index) => {
      const fixedIncome = month.fixed_income || [];
      const flexibleIncome = month.flexible_income || [];
      const fixedExpenses = month.fixed_expenses || [];
      const flexibleExpenses = month.flexible_expenses || [];

      // Set the opening balance
      if (index === 0) {
        month.opening_balance = month.opening_balance || 0;
      } else {
        month.opening_balance = previousForecastBalance;
      }

      // Calculate total income and expenses
      const totalIncome =
        fixedIncome
          .filter((item) => !item.hide)
          .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0) +
        flexibleIncome
          .filter((item) => !item.hide)
          .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
      const totalExpenses =
        fixedExpenses
          .filter((item) => !item.hide)
          .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0) +
        flexibleExpenses
          .filter((item) => !item.hide)
          .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);

      // Calculate current month balances
      const currentFixedIncome = fixedIncome
        .filter((item) => item.current && !item.hide)
        .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
      const currentFlexibleIncome = flexibleIncome
        .filter((item) => item.current && !item.hide)
        .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
      const currentFixedExpenses = fixedExpenses
        .filter((item) => item.current && !item.hide)
        .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
      const currentFlexibleExpenses = flexibleExpenses
        .filter((item) => item.current && !item.hide)
        .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);

      const totalCurrentIncome = currentFixedIncome + currentFlexibleIncome;
      const totalCurrentExpenses =
        currentFixedExpenses + currentFlexibleExpenses;

      month.current_balance =
        month.opening_balance + totalCurrentIncome - totalCurrentExpenses;

      // Calculate remaining cash budget
      const totalCashExpenses = flexibleExpenses
        .filter((item) => item.category === 'Cash' && !item.hide)
        .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);

      month.remaining_cash_budget =
        (month.cash_budget || 0) - totalCashExpenses;

      // Calculate forecast balance
      if (year === currentYear && index < currentMonthIndex) {
        // For past months in the current year, forecast balance is equal to current balance
        month.forecast_balance = month.current_balance;
      } else {
        // For current and future months, include remaining cash budget in forecast balance calculation
        const remainingCashBudget = month.remaining_cash_budget || 0;
        month.forecast_balance =
          month.opening_balance +
          totalIncome -
          (totalExpenses + remainingCashBudget);
      }

      // Debugging logs
      console.log(`Month: ${month.id}`);
      console.log(`Opening Balance: ${month.opening_balance}`);
      console.log(`Total Income: ${totalIncome}`);
      console.log(`Total Expenses: ${totalExpenses}`);
      console.log(`Forecast Balance: ${month.forecast_balance}`);
      console.log(`Total Current Income: ${totalCurrentIncome}`);
      console.log(`Total Current Expenses: ${totalCurrentExpenses}`);
      console.log(`Current Balance: ${month.current_balance}`);
      console.log(`Remaining Cash Budget: ${month.remaining_cash_budget}`);

      // Update previous forecast balance for the next month's opening balance
      previousForecastBalance = month.forecast_balance;
    });
  };

  const handleCashBudgetChange = (monthId, value) => {
    setCashBudget((prev) => ({ ...prev, [monthId]: value }));
  };

  const handleCashNotesChange = (monthId, value) => {
    setCashNotes((prev) => ({ ...prev, [monthId]: value }));
  };

  const toggleCategoryVisibility = (monthId, category) => {
    setCategoryVisibility((prev) => ({
      ...prev,
      [monthId]: {
        ...prev[monthId],
        [category]: !prev[monthId]?.[category],
      },
    }));
  };

  const handleEditRow = (monthId, type, item) => {
    setEditableRow({ monthId, type, ...item });
    setEditMonth(monthId);
  };

  const handleUpdateRow = async (e) => {
    e.preventDefault();
    const { monthId, type, id, description, amount, category, current, hide } =
      editableRow;
    if (!id) {
      console.error('No document ID found.');
      return;
    }
    try {
      const docRef = doc(firestore, `${currentYear}/${monthId}/${type}/${id}`);
      console.log('Updating document:', docRef.path); // Log the document path
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        await updateDoc(docRef, {
          description: description || '',
          amount: parseFloat(amount) || 0,
          category: category || '',
          current: current !== undefined ? current : false,
          hide: hide !== undefined ? hide : false,
        });

        const updatedMonths = months.map((month) => {
          if (month.id === monthId) {
            month[type] = month[type].map((item) =>
              item.id === id ? editableRow : item
            );
          }
          return month;
        });

        calculateBalances(updatedMonths, currentYear);
        setMonths(updatedMonths);

        setEditableRow(null);
        setEditMonth(null);
      } else {
        console.error('Document does not exist:', docRef.path); // Log the missing document path
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  const toggleAddIncomeForm = () => {
    setShowAddIncomeForm((prev) => !prev);
  };

  const toggleAddExpenseForm = () => {
    setShowAddExpenseForm((prev) => !prev);
  };

  const handleDeleteRow = async (monthId, type, id) => {
    if (!window.confirm('Are you sure you want to delete this transaction?'))
      return;

    if (!id) {
      console.error('No document ID found.');
      return;
    }
    try {
      const docRef = doc(firestore, `${currentYear}/${monthId}/${type}/${id}`);
      console.log('Deleting document:', docRef.path); // Log the document path
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        await deleteDoc(docRef);

        const updatedMonths = months.map((month) => {
          if (month.id === monthId) {
            return {
              ...month,
              [type]: month[type].filter((item) => item.id !== id),
            };
          }
          return month;
        });

        calculateBalances(updatedMonths, currentYear);
        setMonths(updatedMonths);
      } else {
        console.error('Document does not exist:', docRef.path); // Log the missing document path
      }
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const handleDeleteAllData = async () => {
    if (!window.confirm('Are you sure you want to delete all data?')) return;

    try {
      console.log('Deleting all data...');
      const monthsSnapshot = await getDocs(collection(firestore, currentYear));
      await Promise.all(
        monthsSnapshot.docs.map(async (monthDoc) => {
          const subCollections = [
            'fixed_income',
            'fixed_expenses',
            'flexible_income',
            'flexible_expenses',
          ];
          await Promise.all(
            subCollections.map(async (subCollection) => {
              const subCollectionSnapshot = await getDocs(
                collection(
                  firestore,
                  `${currentYear}/${monthDoc.id}/${subCollection}`
                )
              );
              await Promise.all(
                subCollectionSnapshot.docs.map(async (doc) => {
                  await deleteDoc(doc.ref);
                })
              );
            })
          );
          await deleteDoc(monthDoc.ref);
        })
      );
      setMonths([]);
      console.log('All data deleted.');
      await rebuildDatabaseStructure();
    } catch (error) {
      console.error('Error deleting data: ', error);
    }
  };

  const handleAddFlexibleExpense = async (monthId) => {
    const newExpense = {
      ...newFlexibleExpense,
      current: true, // Ensure current is true
      last_updated: serverTimestamp(),
    };

    const docRef = await addDoc(
      collection(firestore, `${currentYear}/${monthId}/flexible_expenses`),
      newExpense
    );

    const updatedMonths = months.map((month) => {
      if (month.id === monthId) {
        return {
          ...month,
          flexible_expenses: [
            ...month.flexible_expenses,
            { id: docRef.id, ...newExpense },
          ],
        };
      }
      return month;
    });

    setMonths(updatedMonths);
    setNewFlexibleExpense({
      amount: '',
      description: '',
      category: 'Cash', // Ensure category is set to "Cash"
      hide: false,
      current: true, // Ensure current is true
    });

    calculateBalances(updatedMonths, currentYear);
  };

  const handleAddFlexibleIncome = async (monthId) => {
    const newIncome = {
      ...newFlexibleIncome,
      current: true, // Ensure current is true
      last_updated: serverTimestamp(),
    };

    const docRef = await addDoc(
      collection(firestore, `${currentYear}/${monthId}/flexible_income`),
      newIncome
    );

    const updatedMonths = months.map((month) => {
      if (month.id === monthId) {
        return {
          ...month,
          flexible_income: [
            ...month.flexible_income,
            { id: docRef.id, ...newIncome },
          ],
        };
      }
      return month;
    });

    setMonths(updatedMonths);
    setNewFlexibleIncome({
      amount: '',
      description: '',
      category: 'Misc', // Ensure category is set to "Misc"
      hide: false,
      current: true, // Ensure current is true
    });

    calculateBalances(updatedMonths, currentYear);
  };

  // const handleToggleHide = async (monthId, type, item) => {
  //   try {
  //     const docRef = doc(
  //       firestore,
  //       `${currentYear}/${monthId}/${type}/${item.id}`
  //     );
  //     await updateDoc(docRef, { hide: !item.hide });

  //     setMonths(
  //       months.map((month) => {
  //         if (month.id === monthId) {
  //           month[type] = month[type].map((i) =>
  //             i.id === item.id ? { ...i, hide: !i.hide } : i
  //           );
  //         }
  //         return month;
  //       })
  //     );

  //     calculateBalances(months);
  //   } catch (error) {
  //     console.error("Error toggling hide:", error);
  //   }
  // };

  const handleToggleCurrent = async (monthId, type, item) => {
    try {
      const docRef = doc(
        firestore,
        `${currentYear}/${monthId}/${type}/${item.id}`
      );
      await updateDoc(docRef, { current: !item.current });

      const updatedMonths = months.map((month) => {
        if (month.id === monthId) {
          return {
            ...month,
            [type]: month[type].map((i) =>
              i.id === item.id ? { ...i, current: !i.current } : i
            ),
          };
        }
        return month;
      });

      // Recalculate balances with the updated months data
      calculateBalances(updatedMonths, currentYear);

      // Update the state with the recalculated months
      setMonths(updatedMonths);
    } catch (error) {
      console.error('Error toggling current:', error);
    }
  };

  const handleUpdateCashBudgetAndNotes = async (monthId) => {
    try {
      const updatedCashBudget = parseFloat(cashBudget[monthId]) || 0;
      const updatedCashNotes = cashNotes[monthId] || '';

      const docRef = doc(firestore, `${currentYear}/${monthId}`);
      await updateDoc(docRef, {
        cash_budget: updatedCashBudget,
        cash_notes: updatedCashNotes,
      });

      const updatedMonths = months.map((month) => {
        if (month.id === monthId) {
          // Recalculate the remaining cash budget
          const totalCashExpenses = month.flexible_expenses
            .filter((item) => item.category === 'Cash' && !item.hide)
            .reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
          const remainingCashBudget = updatedCashBudget - totalCashExpenses;

          return {
            ...month,
            cash_budget: updatedCashBudget,
            cash_notes: updatedCashNotes,
            remaining_cash_budget: remainingCashBudget,
          };
        }
        return month;
      });

      setMonths(updatedMonths);
      setCashEditMode((prev) => ({ ...prev, [monthId]: false }));
      calculateBalances(updatedMonths, currentYear);
    } catch (error) {
      console.error('Error updating cash budget and notes:', error);
    }
  };

  const handleToggleCategoryCurrent = async (
    monthId,
    type,
    category,
    isChecked
  ) => {
    try {
      const updates = [];

      const updatedMonths = months.map((month) => {
        if (month.id === monthId) {
          return {
            ...month,
            [type]: month[type].map((item) => {
              if (item.category === category) {
                const updatedItem = { ...item, current: isChecked };
                updates.push(
                  updateDoc(
                    doc(
                      firestore,
                      `${currentYear}/${monthId}/${type}/${item.id}`
                    ),
                    { current: isChecked }
                  )
                );
                return updatedItem;
              }
              return item;
            }),
          };
        }
        return month;
      });

      // Wait for all updates to complete
      await Promise.all(updates);

      // Recalculate balances with the updated months data
      calculateBalances(updatedMonths, currentYear);

      // Update the state with the recalculated months
      setMonths(updatedMonths);
    } catch (error) {
      console.error('Error toggling current for category:', error);
    }
  };

  const handleToggleShowPreviousMonths = async () => {
    const newValue = !showPreviousMonths;
    setShowPreviousMonths(newValue);

    const settingsDocRef = doc(firestore, 'settings', 'settings');
    await updateDoc(settingsDocRef, {
      show_all_months: newValue,
    });
  };

  const handleToggleShowTransactions = async () => {
    const newValue = !showTransactions;
    setShowTransactions(newValue);
  
    const settingsDocRef = doc(firestore, 'settings', 'settings');
    await updateDoc(settingsDocRef, {
      show_transactions: newValue,
    });
  
    if (newValue) {
      // Reset category visibility state when showing transactions globally
      const newVisibility = {};
      months.forEach(month => {
        newVisibility[month.id] = {};
        month.flexible_expenses.forEach(expense => {
          newVisibility[month.id][expense.category] = true;
        });
        month.fixed_expenses.forEach(expense => {
          newVisibility[month.id][expense.category] = true;
        });
        month.flexible_income.forEach(income => {
          newVisibility[month.id][income.category] = true;
        });
        month.fixed_income.forEach(income => {
          newVisibility[month.id][income.category] = true;
        });
      });
      setCategoryVisibility(newVisibility);
    } else {
      // Clear category visibility state when hiding transactions globally
      setCategoryVisibility({});
    }
  };

  const renderFields = (items, type, monthId) => {
    const groupedByCategory = items.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {});
  
    const sortedGroups = Object.keys(groupedByCategory).map((category) => ({
      category,
      items: groupedByCategory[category].sort(
        (a, b) => parseFloat(b.amount) - parseFloat(a.amount)
      ),
    }));
  
    return sortedGroups.map((group) => {
      const isCategoryChecked = group.items.every((item) => item.current);
      const categoryTotal = group.items.reduce(
        (sum, item) => sum + parseFloat(item.amount || 0),
        0
      );
  
      const isVisible =
        showTransactions ||
        (categoryVisibility[monthId]?.[group.category] !== undefined
          ? categoryVisibility[monthId]?.[group.category]
          : showTransactions);
  
      return (
        <div key={group.category} className="mb-4">
          <h4 className="text-lg font-semibold flex items-center dark:text-slate-400">
            <input
              type="checkbox"
              checked={isCategoryChecked}
              onChange={(e) =>
                handleToggleCategoryCurrent(
                  monthId,
                  type,
                  group.category,
                  e.target.checked
                )
              }
              className={`form-checkbox mr-2 ${
                type === 'flexible_expenses' || type === 'fixed_expenses'
                  ? 'form-checkbox--expense'
                  : ''
              }`}
            />
            <span
              className="flex justify-between items-end w-full cursor-pointer"
              onClick={() => toggleCategoryVisibility(monthId, group.category)}
            >
              <span>{group.category ? group.category : ''}</span>
              <span className="border-b flex-1 border-dashed border-slate-400 dark:border-slate-600 mb-1"></span>
              <span className="">£{categoryTotal.toFixed(2)}</span>
            </span>
          </h4>
          {isVisible &&
            group.items.map((item) => (
              <div
                key={`${monthId}-${item.id}`}
                className="flex justify-between items-center mb-1"
              >
                {editableRow &&
                editableRow.id === item.id &&
                editMonth === monthId ? (
                  <form
                    onSubmit={handleUpdateRow}
                    className="flex flex-col w-full mt-2"
                  >
                    <input
                      type="text"
                      value={editableRow.description}
                      onChange={(e) =>
                        setEditableRow({
                          ...editableRow,
                          description: e.target.value,
                        })
                      }
                      className="mb-2 p-2 border border-gray-300 rounded flex-grow dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
                    />
                    <input
                      type="number"
                      value={editableRow.amount}
                      onChange={(e) => {
                        const value = e.target.value;
                        setEditableRow({
                          ...editableRow,
                          amount: value === '' ? '' : parseFloat(value),
                        });
                      }}
                      className="mb-2 p-2 border border-gray-300 rounded flex-grow dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
                    />
                    <input
                      type="text"
                      value={editableRow.category}
                      onChange={(e) =>
                        setEditableRow({
                          ...editableRow,
                          category: e.target.value,
                        })
                      }
                      className="mb-2 p-2 border border-gray-300 rounded flex-grow dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
                    />
                    <button
                      type="submit"
                      className={`mb-2 text-white px-4 py-2 rounded ${
                        type === 'flexible_expenses' ||
                        type === 'fixed_expenses'
                          ? 'bg-rose-600'
                          : 'bg-lime-600'
                      }`}
                    >
                      Save
                    </button>
                    <button
                      onClick={() => handleDeleteRow(monthId, type, item.id)}
                      className="mb-2 bg-amber-600 text-white px-4 py-2 rounded"
                    >
                      Delete
                    </button>
                    <button
                      onClick={() => {
                        setEditableRow(null);
                        setEditMonth(null);
                      }}
                      className="bg-gray-500 text-white px-4 py-2 rounded"
                    >
                      Cancel
                    </button>
                  </form>
                ) : (
                  <div className="flex justify-between items-center w-full">
                    <label className="mr-2 ml-4">
                      <input
                        type="checkbox"
                        checked={item.current || false}
                        onChange={() =>
                          handleToggleCurrent(monthId, type, item)
                        }
                        className={`form-checkbox ${
                          type === 'flexible_expenses' ||
                          type === 'fixed_expenses'
                            ? 'form-checkbox--expense'
                            : ''
                        }`}
                      />
                    </label>
                    <button
                      className="flex items-end w-full justify-between text-left hover:cursor-pointer leading-snug"
                      onClick={() => handleEditRow(monthId, type, item)}
                    >
                      <span className="inline-block truncate mr-1 dark:text-slate-400">
                        {item.description}
                      </span>
                      <span className="border-b flex-1 border-dashed border-slate-400 dark:border-slate-600 mb-1"></span>
                      <span
                        className={`inline-block ml-1 text-slate-600 dark:text-slate-400  ${
                          ['flexible_expenses', 'fixed_expenses'].includes(
                            type
                          ) && item.current
                            ? 'text-rose-600 dark:text-rose-600 font-semibold'
                            : ''
                        } ${
                          ['flexible_income', 'fixed_income'].includes(type) &&
                          item.current
                            ? ' text-lime-600 dark:text-lime-600 font-semibold'
                            : ''
                        }`}
                      >
                        £{parseFloat(item.amount || 0).toFixed(2)}
                      </span>
                    </button>
                  </div>
                )}
              </div>
            ))}
        </div>
      );
    });
  };

  const currentMonthName = new Date()
    .toLocaleString('default', { month: 'short' })
    .toLowerCase();

  return (
    <div className="p-2">
      <nav className="mb-3">
        <ul className="flex overflow-x-auto no-scrollbar">
          {months.map((month, index) => (
            <li
              key={month.id}
              className={`w-24 mr-4 pr-4 ${
                index !== months.length - 1
                  ? 'border-r border-slate-300 dark:border-slate-600'
                  : ''
              } md:w-full leading-tight ${
                !showPreviousMonths && index < currentMonthIndex ? 'hidden' : ''
              }`}
            >
              <a href={`#${month.id}`}>
                <span className="text-slate-600 dark:text-slate-200 font-semibold uppercase">
                  {month.id}
                </span>
                <span
                  className={`block ${
                    month.forecast_balance < 100
                      ? 'text-rose-600 font-semibold'
                      : month.forecast_balance < 500
                        ? 'text-amber-600 font-semibold'
                        : 'text-lime-600 font-semibold'
                  }`}
                >
                  £{month.forecast_balance.toFixed(2)}
                </span>
                <span className="block text-slate-500 dark:text-slate-400 text-sm">
                  £{month.cash_budget.toFixed(2)}
                </span>
                <span className="block text-slate-500 dark:text-slate-400 text-xs truncate">
                  {month.cash_notes}
                </span>
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <div className="flex justify-between items-center mb-2">
        <h1 className="text-2xl font-semibold text-slate-600 dark:text-slate-200">
          {currentYear}
        </h1>
        <div className="flex justify-between items-center">
          <span className="ml-4 mr-2 text-sm text-slate-600 dark:text-slate-400">
            Show Transactions
          </span>
          <label className="relative inline-flex cursor-pointer items-center">
            <input
              id="transaction-switch"
              type="checkbox"
              className="peer sr-only"
              checked={showTransactions}
              onChange={handleToggleShowTransactions}
            />
            <label htmlFor="transaction-switch" className="hidden"></label>
            <div className="peer h-6 w-11 rounded-full border dark:border-slate-800 bg-slate-200 dark:bg-slate-400 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 dark:after:border-gray-400 after:bg-white dark:after:bg-slate-800 after:transition-all after:content-[''] peer-checked:bg-lime-600 peer-checked:after:translate-x-full peer-checked:after:border-white dark:peer-checked:after:border-gray-400 peer-focus:ring-green-300"></div>
          </label>
          <span className="mx-2 text-sm text-slate-600 dark:text-slate-400">
            {showPreviousMonths ? '12 Months' : '12 Months'}
          </span>
          <label className="relative inline-flex cursor-pointer items-center">
            <input
              id="switch"
              type="checkbox"
              className="peer sr-only"
              checked={showPreviousMonths}
              onChange={handleToggleShowPreviousMonths}
            />
            <label htmlFor="switch" className="hidden"></label>
            <div className="peer h-6 w-11 rounded-full border dark:border-slate-800 bg-slate-200 dark:bg-slate-400 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 dark:after:border-gray-400 after:bg-white dark:after:bg-slate-800 after:transition-all after:content-[''] peer-checked:bg-lime-600 peer-checked:after:translate-x-full peer-checked:after:border-white dark:peer-checked:after:border-gray-400 peer-focus:ring-green-300"></div>
          </label>
        </div>
      </div>
      {months.length === 0 ? (
        <div className="min-h-screen w-full flex justify-center items-center">
          <svg
            className="animate-spin h-10 w-10"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="#475569"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="#475569"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : (
        months.map((month, index) => (
          <div
            key={month.id}
            id={month.id}
            className={`mb-8 border-2 rounded-lg bg-slate-100 dark:border-slate-700 dark:bg-slate-800 ${
              !showPreviousMonths && index < currentMonthIndex ? 'hidden' : ''
            }`}
          >
            <div
              className={`month-header sticky top-0 px-2 py-1 mb-1 rounded-t-lg bg-slate-200 dark:bg-slate-700 ${
                month.id === currentMonthName ? 'custom-class' : ''
              }`}
            >
              <div className="flex justify-between items-center">
                <h2
                  className={`text-2xl font-semibold ${
                    month.id === currentMonthName
                      ? 'text-lime-600'
                      : 'text-slate-600 dark:text-slate-200'
                  }`}
                >
                  {fullMonthNames[month.id]}
                </h2>
                <span className="">
                  <a href="#top">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="-0.5 -0.5 18 18"
                      id="Arrow-Thick-Up-2--Streamline-Streamline--3.0"
                      height="18"
                      width="18"
                    >
                      <path
                        d="M15.873750000000001 7.225 9.002916666666668 0.2833333333333334A0.7083333333333334 0.7083333333333334 0 0 0 8.5 0.07083333333333335a0.7083333333333334 0.7083333333333334 0 0 0 -0.5029166666666667 0.20541666666666666L1.1120833333333335 7.083333333333334a1.8416666666666668 1.8416666666666668 0 0 0 0.07083333333333335 2.5925000000000002 1.8416666666666668 1.8416666666666668 0 0 0 2.5925000000000002 0.07083333333333335L6.4245833333333335 7.083333333333334a0.18416666666666667 0.18416666666666667 0 0 1 0.3045833333333333 0.1275v8.294583333333334c0 1.1050000000000002 1.1758333333333333 1.4166666666666667 1.7708333333333335 1.4166666666666667a1.615 1.615 0 0 0 1.7708333333333335 -1.4166666666666667V7.253333333333334a0.17708333333333334 0.17708333333333334 0 0 1 0.11333333333333334 -0.16291666666666668 0.16291666666666668 0.16291666666666668 0 0 1 0.19125000000000003 0.0425L13.210416666666667 9.916666666666668a1.8841666666666668 1.8841666666666668 0 0 0 2.663333333333333 -2.65625Z"
                        fill="#94a3b8"
                        strokeWidth="1"
                      ></path>
                    </svg>
                  </a>
                </span>
              </div>
              <div className="flex justify-between items-center">
                {/* <p>
                Opening: £
                {isNaN(month.opening_balance)
                  ? 0
                  : month.opening_balance.toFixed(2)}
              </p> */}
                <div className="w-1/2 flex justify-between pr-4">
                  <span className="text-slate-400">Current: </span>
                  <span className="font-semibold text-slate-600 dark:text-slate-200">
                    £
                    {isNaN(month.current_balance)
                      ? 0
                      : month.current_balance.toFixed(2)}
                  </span>
                </div>
                <div className="w-1/2 flex justify-between pl-4">
                  <span className="text-slate-400">Forecast:&nbsp;</span>
                  <span
                    className={`${
                      month.forecast_balance < 100
                        ? 'text-rose-600 font-semibold'
                        : month.forecast_balance < 500
                          ? 'text-amber-600 font-semibold'
                          : 'text-lime-600 font-semibold'
                    }`}
                  >
                    £
                    {isNaN(month.forecast_balance)
                      ? 0
                      : month.forecast_balance.toFixed(2)}
                  </span>
                </div>
              </div>

              <div className="mb-2">
                {/* <p>
                Opening: £
                {isNaN(month.opening_balance)
                  ? 0
                  : month.opening_balance.toFixed(2)}
              </p> */}
                <div className="">
                  {cashEditMode[month.id] ? (
                    <div className="flex flex-col">
                      <input
                        type="number"
                        value={cashBudget[month.id] || ''}
                        onChange={(e) =>
                          handleCashBudgetChange(month.id, e.target.value)
                        }
                        placeholder="Cash Budget"
                        className="p-2 border border-gray-300 rounded mb-2 dark:bg-slate-800 dark:text-slate-200 dark:border-slate-600"
                      />
                      <input
                        type="text"
                        value={cashNotes[month.id] || ''}
                        onChange={(e) =>
                          handleCashNotesChange(month.id, e.target.value)
                        }
                        placeholder="Notes"
                        className="p-2 border border-gray-300 rounded mb-2 dark:bg-slate-800 dark:text-slate-200 dark:border-slate-600"
                      />
                      <button
                        onClick={() => handleUpdateCashBudgetAndNotes(month.id)}
                        className="bg-lime-600 text-white px-4 py-2 rounded mb-2"
                      >
                        Save
                      </button>
                      <button
                        onClick={() =>
                          setCashEditMode((prev) => ({
                            ...prev,
                            [month.id]: false,
                          }))
                        }
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="flex justify-between">
                        <div className="w-1/2 flex justify-between pr-4">
                          <span className="text-slate-400">Cash Budget:</span>
                          <button
                            onClick={() =>
                              setCashEditMode((prev) => ({
                                ...prev,
                                [month.id]: true,
                              }))
                            }
                            className="font-semibold text-slate-600 dark:text-slate-200 underline"
                          >
                            £{parseFloat(cashBudget[month.id] || 0).toFixed(2)}
                          </button>
                        </div>
                        <div className="w-1/2 flex justify-between pl-4">
                          <span className="text-slate-400">Remaining: </span>
                          <span className="font-semibold text-slate-600 dark:text-slate-200">
                            £
                            {isNaN(month.remaining_cash_budget)
                              ? 0
                              : month.remaining_cash_budget.toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <span className="text-slate-400 flex items-center text-sm">
                        {cashNotes &&
                          cashNotes[month.id] &&
                          `~${cashNotes[month.id]}`}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="px-2">
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-xl font-semibold text-rose-600 mr-2">
                  Flexible Expenses
                </h3>
                <button
                  onClick={toggleAddExpenseForm}
                  className="text-rose-600 underline"
                >
                  {showAddExpenseForm ? 'Cancel' : 'Add'}
                </button>
              </div>

              {showAddExpenseForm && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAddFlexibleExpense(month.id);
                  }}
                  className="flex flex-col mb-4"
                >
                  <input
                    type="text"
                    placeholder="Description"
                    value={newFlexibleExpense.description}
                    onChange={(e) =>
                      setNewFlexibleExpense({
                        ...newFlexibleExpense,
                        description: e.target.value,
                      })
                    }
                    className="mb-2 p-2 border border-gray-300 rounded dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
                  />
                  <input
                    type="number"
                    placeholder="Amount"
                    value={newFlexibleExpense.amount}
                    onChange={(e) =>
                      setNewFlexibleExpense({
                        ...newFlexibleExpense,
                        amount: e.target.value,
                      })
                    }
                    className="mb-2 p-2 border border-gray-300 rounded dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
                  />
                  <input
                    type="text"
                    placeholder="Category"
                    value={newFlexibleExpense.category}
                    onChange={(e) =>
                      setNewFlexibleExpense({
                        ...newFlexibleExpense,
                        category: e.target.value,
                      })
                    }
                    className="mb-2 p-2 border border-gray-300 rounded dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
                  />
                  <button
                    type="submit"
                    className="bg-rose-600 text-white px-4 py-2 rounded"
                  >
                    Add Flexible Expense
                  </button>
                </form>
              )}
              {renderFields(
                month.flexible_expenses,
                'flexible_expenses',
                month.id
              )}

              <hr className="mb-1 border-slate-300 dark:border-slate-700" />
              <h3 className="text-xl font-semibold text-rose-600 mb-2">
                Fixed Expenses
              </h3>
              {renderFields(month.fixed_expenses, 'fixed_expenses', month.id)}
              <hr className="mb-1 border-slate-300 dark:border-slate-700" />
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-xl text-lime-600 font-semibold mr-2">
                  Flexible Income
                </h3>
                <button
                  onClick={toggleAddIncomeForm}
                  className="text-lime-600 underline"
                >
                  {showAddIncomeForm ? 'Cancel' : 'Add'}
                </button>
              </div>
              {showAddIncomeForm && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAddFlexibleIncome(month.id);
                  }}
                  className="flex flex-col mb-4"
                >
                  <input
                    type="text"
                    placeholder="Description"
                    value={newFlexibleIncome.description}
                    onChange={(e) =>
                      setNewFlexibleIncome({
                        ...newFlexibleIncome,
                        description: e.target.value,
                      })
                    }
                    className="mb-2 p-2 border border-gray-300 rounded dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
                  />
                  <input
                    type="number"
                    placeholder="Amount"
                    value={newFlexibleIncome.amount}
                    onChange={(e) =>
                      setNewFlexibleIncome({
                        ...newFlexibleIncome,
                        amount: e.target.value,
                      })
                    }
                    className="mb-2 p-2 border border-gray-300 rounded dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
                  />
                  <input
                    type="text"
                    placeholder="Category"
                    value={newFlexibleIncome.category}
                    onChange={(e) =>
                      setNewFlexibleIncome({
                        ...newFlexibleIncome,
                        category: e.target.value,
                      })
                    }
                    className="mb-2 p-2 border border-gray-300 rounded dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
                  />
                  <button
                    type="submit"
                    className="bg-lime-600 text-white px-4 py-2 rounded"
                  >
                    Add Flexible Income
                  </button>
                </form>
              )}
              {renderFields(month.flexible_income, 'flexible_income', month.id)}
              <hr className="mb-1 border-slate-300 dark:border-slate-700" />
              <h3 className="text-xl text-lime-600 font-semibold mb-2">
                Fixed Income
              </h3>
              {renderFields(month.fixed_income, 'fixed_income', month.id)}
            </div>
          </div>
        ))
      )}
      <button
        onClick={handleDeleteAllData}
        className="bg-red-500 text-white px-4 py-2 rounded mb-4"
      >
        Delete All Data
      </button>
    </div>
  );
};

export default Current;
