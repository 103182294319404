// src/App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import NavBar from "./NavBar";
import Master from "./Master";
import Current from "./Current";
import Login from "./Login";
import Settings from "./Settings";
import { AuthProvider } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import Calculator from "./Calculator";
import { CurrentYearProvider } from "./CurrentYearContext";
import { firestore } from './firebase';
import { doc, getDoc } from 'firebase/firestore';

function App() {
  const [lightDarkMode, setLightDarkMode] = useState('system');

  useEffect(() => {
    const fetchSettings = async () => {
      const settingsDoc = await getDoc(doc(firestore, 'settings', 'settings'));
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        setLightDarkMode(data.light_dark_mode || 'system');

        if (data.light_dark_mode === 'light') {
          document.documentElement.classList.remove('dark');
          localStorage.theme = 'light';
        } else if (data.light_dark_mode === 'dark') {
          document.documentElement.classList.add('dark');
          localStorage.theme = 'dark';
        } else {
          localStorage.removeItem('theme');
          if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            document.documentElement.classList.add('dark');
          } else {
            document.documentElement.classList.remove('dark');
          }
        }
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const systemPreferenceListener = (e) => {
      if (lightDarkMode === 'system') {
        if (e.matches) {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
      }
    };

    if (lightDarkMode === 'system') {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      mediaQuery.addListener(systemPreferenceListener);
      return () => mediaQuery.removeListener(systemPreferenceListener);
    }
  }, [lightDarkMode]);

  return (
    <Router>
      <AuthProvider>
        <CurrentYearProvider>
          <NavBar />
          <div className="max-w-md mx-auto dark:bg-slate-800 min-h-screen">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Current />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/master"
                element={
                  <ProtectedRoute>
                    <Master />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/calculator"
                element={
                  <ProtectedRoute>
                    <Calculator />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
        </CurrentYearProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
