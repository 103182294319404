import React, { useState, useEffect } from 'react';
import { firestore } from './firebase'; // Ensure you have your firebase setup properly
import { doc, getDoc, setDoc } from 'firebase/firestore';

const Calculator = () => {
  const [inputs, setInputs] = useState(Array(10).fill({ number: '', note: '' }));
  const [amount, setAmount] = useState('');
  const [taxRate, setTaxRate] = useState(''); // Default tax rate input is empty
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch tax rate and wish list from Firestore
    const fetchData = async () => {
      try {
        // Fetch tax rate
        const percentageDocRef = doc(firestore, 'calculator', 'percentage');
        const percentageDoc = await getDoc(percentageDocRef);
        if (percentageDoc.exists()) {
          const fetchedRate = percentageDoc.data().percentage;
          setTaxRate(fetchedRate === 40 ? '' : fetchedRate.toString());
        }

        // Fetch wish list
        const wishListDocRef = doc(firestore, 'calculator', 'wish_list');
        const wishListDoc = await getDoc(wishListDocRef);
        if (wishListDoc.exists()) {
          setInputs(wishListDoc.data().items || Array(10).fill({ number: '', note: '' }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (index, field, value) => {
    const newInputs = [...inputs];
    newInputs[index] = { ...newInputs[index], [field]: value };
    setInputs(newInputs);
  };

  const getSum = () => {
    return inputs.reduce((acc, curr) => {
      const num = parseFloat(curr.number);
      return acc + (isNaN(num) ? 0 : num);
    }, 0);
  };

  const calculateTax = () => {
    const amountNumber = parseFloat(amount);
    const taxRateNumber = taxRate === '' ? 40 : parseFloat(taxRate);
    if (isNaN(amountNumber) || isNaN(taxRateNumber)) {
      return 0;
    }
    return (amountNumber * taxRateNumber) / 100;
  };

  const saveTaxRate = async () => {
    if (taxRate !== '') {
      try {
        const percentageDocRef = doc(firestore, 'calculator', 'percentage');
        await setDoc(percentageDocRef, { percentage: parseFloat(taxRate) });
      } catch (error) {
        console.error('Error saving tax rate:', error);
      }
    }
  };

  const saveWishList = async () => {
    try {
      const wishListDocRef = doc(firestore, 'calculator', 'wish_list');
      await setDoc(wishListDocRef, { items: inputs });
    } catch (error) {
      console.error('Error saving wish list:', error);
    }
  };

  const clearWishList = async () => {
    const clearedInputs = Array(10).fill({ number: '', note: '' });
    setInputs(clearedInputs);
    try {
      const wishListDocRef = doc(firestore, 'calculator', 'wish_list');
      await setDoc(wishListDocRef, { items: clearedInputs });
    } catch (error) {
      console.error('Error clearing wish list:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="calculator-container p-2">
      <h2 className="text-xl font-bold mb-2 dark:text-slate-200">Percentage</h2>
      <div className="tax-display text-2xl font-bold mb-4 dark:text-slate-200">
        £{calculateTax().toFixed(2)}
      </div>
      <div className="mb-4 flex items-center space-x-2">
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="p-2 border border-gray-300 rounded w-1/2 dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
          placeholder="Amount"
        />
        <input
          type="number"
          value={taxRate}
          onChange={(e) => setTaxRate(e.target.value)}
          onBlur={saveTaxRate} // Save the tax rate on blur event
          className="p-2 border border-gray-300 rounded w-1/2 dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
          placeholder="40%"
        />
      </div>

      <h2 className="text-xl font-bold mb-2 dark:text-slate-200">Wishlist</h2>
      <div className="flex justify-between items-center mb-2">
        <div className="sum-display text-2xl font-bold dark:text-slate-200">
          £{getSum()}
        </div>
        <button
          onClick={clearWishList}
          className="text-white bg-lime-600 px-2 py-1 rounded"
        >
          Clear
        </button>
      </div>

      {inputs.map((input, index) => (
        <div key={index} className="mb-2 flex items-center space-x-2">
          <input
            type="number"
            value={input.number}
            onChange={(e) => handleChange(index, 'number', e.target.value)}
            onBlur={saveWishList} // Save the wish list on blur event
            className="p-2 border border-gray-300 rounded w-1/2 dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
            placeholder="Amount"
          />
          <input
            type="text"
            value={input.note}
            onChange={(e) => handleChange(index, 'note', e.target.value)}
            onBlur={saveWishList} // Save the wish list on blur event
            className="p-2 border border-gray-300 rounded w-1/2 dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
            placeholder="Item"
          />
        </div>
      ))}
    </div>
  );
};

export default Calculator;
