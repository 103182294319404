import React, { useState, useEffect, useContext } from 'react';
import { firestore, functions } from './firebase';
import {
  collection,
  getDoc,
  getDocs,
  setDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { CurrentYearContext } from './CurrentYearContext';

const Master = () => {
  const [expenses, setExpenses] = useState([]);
  const [income, setIncome] = useState([]);
  const [newExpense, setNewExpense] = useState({
    amount: '',
    description: '',
    category: '',
    hide: false,
  });
  const [newIncome, setNewIncome] = useState({
    amount: '',
    description: '',
    category: '',
    hide: false,
  });
  const [editExpense, setEditExpense] = useState(null);
  const [editIncome, setEditIncome] = useState(null);
  const [showAddExpenseForm, setShowAddExpenseForm] = useState(false);
  const [showAddIncomeForm, setShowAddIncomeForm] = useState(false);
  const [cashBudget, setCashBudget] = useState('');
  const [editCashBudget, setEditCashBudget] = useState(null);

  const { currentYear } = useContext(CurrentYearContext);

  const monthOrder = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];
  const allMonths = monthOrder;
  const currentMonthIndex = new Date().getMonth();
  const remainingMonths = monthOrder.slice(currentMonthIndex);

  useEffect(() => {
    const fetchData = async () => {
      const expensesSnapshot = await getDocs(
        collection(firestore, 'master_fixed_expenses')
      );
      const incomeSnapshot = await getDocs(
        collection(firestore, 'master_fixed_income')
      );

      const cashBudgetDoc = await getDoc(
        doc(firestore, 'master_flexible_expenses', 'cash_budget')
      );
      const cashBudgetValue = cashBudgetDoc.exists()
        ? cashBudgetDoc.data().cash_budget
        : '';

      setExpenses(
        expensesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
      setIncome(
        incomeSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
      setCashBudget(cashBudgetValue);
    };

    if (currentYear) {
      fetchData();
    }
  }, [currentYear]);

  const handleAddExpense = async () => {
    if (!currentYear) return;

    const docRef = await addDoc(
      collection(firestore, 'master_fixed_expenses'),
      {
        ...newExpense,
        last_updated: serverTimestamp(),
      }
    );
    setExpenses([...expenses, { id: docRef.id, ...newExpense }]);
    setNewExpense({ amount: '', description: '', category: '', hide: false });

    // Add new expense to the current and subsequent months
    await updateCurrentYearEntries(docRef.id, 'fixed_expenses', newExpense);
  };

  const handleAddIncome = async () => {
    if (!currentYear) return;

    const docRef = await addDoc(collection(firestore, 'master_fixed_income'), {
      ...newIncome,
      last_updated: serverTimestamp(),
    });
    setIncome([...income, { id: docRef.id, ...newIncome }]);
    setNewIncome({ amount: '', description: '', category: '', hide: false });

    // Add new income to the current and subsequent months
    await updateCurrentYearEntries(docRef.id, 'fixed_income', newIncome);
  };

  const handleUpdateExpense = async (id) => {
    if (!currentYear) return;

    const docRef = doc(firestore, 'master_fixed_expenses', id);
    await updateDoc(docRef, {
      ...editExpense,
      last_updated: serverTimestamp(),
    });

    // Update the relevant entries in the current and subsequent months
    await updateCurrentYearEntries(id, 'fixed_expenses', editExpense);

    setExpenses(
      expenses.map((exp) => (exp.id === id ? { id, ...editExpense } : exp))
    );
    setEditExpense(null);
  };

  const handleUpdateIncome = async (id) => {
    if (!currentYear) return;

    const docRef = doc(firestore, 'master_fixed_income', id);
    await updateDoc(docRef, {
      ...editIncome,
      last_updated: serverTimestamp(),
    });

    // Update the relevant entries in the current and subsequent months
    await updateCurrentYearEntries(id, 'fixed_income', editIncome);

    setIncome(
      income.map((inc) => (inc.id === id ? { id, ...editIncome } : inc))
    );
    setEditIncome(null);
  };

  const handleUpdateCashBudget = async () => {
    try {
      await setDoc(doc(firestore, 'master_flexible_expenses', 'cash_budget'), {
        cash_budget: parseFloat(cashBudget) || 0,
      });
      setEditCashBudget(null); // Reset edit state after updating
    } catch (error) {
      console.error('Error updating cash budget:', error);
      alert('Failed to update cash budget');
    }
  };

  const updateCurrentYearEntries = async (id, type, data) => {
    if (!currentYear) return;

    const updatedData = { ...data, last_updated: serverTimestamp() };
    const monthsToUpdate =
      currentYear === new Date().getFullYear().toString()
        ? remainingMonths
        : allMonths;

    await Promise.all(
      monthsToUpdate.map(async (month) => {
        const monthRef = doc(firestore, `${currentYear}/${month}`);
        const itemRef = doc(monthRef, type, id);
        await setDoc(itemRef, updatedData);
      })
    );
  };

  const handleDeleteExpense = async (id) => {
    if (!currentYear) return;

    await deleteDoc(doc(firestore, 'master_fixed_expenses', id));
    setExpenses(expenses.filter((exp) => exp.id !== id));
    // Delete from current and subsequent months
    await deleteCurrentYearEntries(id, 'fixed_expenses');
  };

  const handleDeleteIncome = async (id) => {
    if (!currentYear) return;

    await deleteDoc(doc(firestore, 'master_fixed_income', id));
    setIncome(income.filter((inc) => inc.id !== id));
    // Delete from current and subsequent months
    await deleteCurrentYearEntries(id, 'fixed_income');
  };

  const deleteCurrentYearEntries = async (id, type) => {
    if (!currentYear) return;

    const monthsToDelete =
      currentYear === new Date().getFullYear().toString()
        ? remainingMonths
        : allMonths;

    await Promise.all(
      monthsToDelete.map(async (month) => {
        const monthRef = doc(firestore, `${currentYear}/${month}`);
        const itemRef = doc(monthRef, type, id);
        await deleteDoc(itemRef);
      })
    );
  };

  const handlePopulateCurrentYear = async () => {
    if (!currentYear) return;

    const populateCurrentYear = httpsCallable(functions, 'populateCurrentYear');
    try {
      await handleUpdateCashBudget();
      const result = await populateCurrentYear({
        year: currentYear,
        cashBudget: parseFloat(cashBudget) || 0,
      });
      console.log(result.data.message);
      alert('Current year populated successfully');
    } catch (error) {
      console.error('Error populating current year:', error);
      alert('Failed to populate current year');
    }
  };

  const renderItems = (items, type) => {
    // Group by category and sort by amount
    const groupedByCategory = items.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {});

    const sortedGroups = Object.keys(groupedByCategory).map((category) => {
      return {
        category,
        items: groupedByCategory[category].sort(
          (a, b) => parseFloat(b.amount) - parseFloat(a.amount)
        ),
      };
    });

    return sortedGroups.map((group) => {
      // Calculate the sum of all items in the category
      const categoryTotal = group.items.reduce(
        (sum, item) => sum + parseFloat(item.amount || 0),
        0
      );

      return (
        <div key={group.category} className="mb-4">
          <h4 className="text-lg font-semibold flex items-center dark:text-slate-400">
            <span className="flex justify-between items-end w-full">
              <span>{group.category ? group.category : ''}</span>
              <span className="border-b flex-1 border-dashed border-slate-400 mb-1"></span>
              <span className="">£{categoryTotal.toFixed(2)}</span>
            </span>
          </h4>
          {group.items.map((item) => (
            <div
              key={item.id}
              className="flex justify-between items-center mb-1"
            >
              {editExpense?.id === item.id || editIncome?.id === item.id ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    type === 'expense'
                      ? handleUpdateExpense(item.id)
                      : handleUpdateIncome(item.id);
                  }}
                  className="flex flex-col w-full"
                >
                  <input
                    type="text"
                    value={editExpense?.description || editIncome?.description}
                    onChange={(e) =>
                      type === 'expense'
                        ? setEditExpense({
                            ...editExpense,
                            description: e.target.value,
                          })
                        : setEditIncome({
                            ...editIncome,
                            description: e.target.value,
                          })
                    }
                    className="mb-2 p-2 border border-gray-300 rounded flex-grow dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
                  />
                  <input
                    type="number"
                    value={editExpense?.amount || editIncome?.amount}
                    onChange={(e) =>
                      type === 'expense'
                        ? setEditExpense({
                            ...editExpense,
                            amount: e.target.value,
                          })
                        : setEditIncome({
                            ...editIncome,
                            amount: e.target.value,
                          })
                    }
                    className="mb-2 p-2 border border-gray-300 rounded flex-grow dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
                  />
                  <input
                    type="text"
                    value={editExpense?.category || editIncome?.category}
                    onChange={(e) =>
                      type === 'expense'
                        ? setEditExpense({
                            ...editExpense,
                            category: e.target.value,
                          })
                        : setEditIncome({
                            ...editIncome,
                            category: e.target.value,
                          })
                    }
                    className="mb-2 p-2 border border-gray-300 rounded flex-grow dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
                  />
                  <label className="inline-block mr-2 dark:text-slate-400">
                    <input
                      type="checkbox"
                      checked={editExpense?.hide || editIncome?.hide}
                      onChange={(e) =>
                        type === 'expense'
                          ? setEditExpense({
                              ...editExpense,
                              hide: e.target.checked,
                            })
                          : setEditIncome({
                              ...editIncome,
                              hide: e.target.checked,
                            })
                      }
                      className="mr-2 mb-2"
                    />
                    Hide
                  </label>
                  <button
                    type="submit"
                    className={`text-white px-4 py-2 rounded mb-2 ${
                      type === 'expense' ? 'bg-rose-600' : 'bg-lime-600'
                    }`}
                  >
                    Update
                  </button>
                  <button
                    onClick={() =>
                      type === 'expense'
                        ? handleDeleteExpense(item.id)
                        : handleDeleteIncome(item.id)
                    }
                    className="bg-amber-600 text-white px-4 py-2 rounded mb-2"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => {
                      type === 'expense'
                        ? setEditExpense(null)
                        : setEditIncome(null);
                    }}
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                  >
                    Cancel
                  </button>
                </form>
              ) : (
                <button
                  className="flex items-end w-full justify-between text-left hover:cursor-pointer leading-snug"
                  onClick={() =>
                    type === 'expense'
                      ? setEditExpense(item)
                      : setEditIncome(item)
                  }
                >
                  <span className="inline-block truncate mr-1 dark:text-slate-400">
                    {item.description}
                  </span>
                  <span className="border-b flex-1 border-dashed border-slate-400 mb-1"></span>
                  <span
                    className={`inline-block ml-1 ${
                      type === 'expense'
                        ? 'text-rose-600 font-semibold'
                        : 'text-lime-600 font-semibold'
                    }`}
                  >
                    £{parseFloat(item.amount || 0).toFixed(2)}
                  </span>
                </button>
              )}
            </div>
          ))}
        </div>
      );
    });
  };

  return (
    <div className="p-2">
      <div className="mb-6">
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-xl font-semibold text-rose-600 mr-2">
            Flexible Expenses
          </h2>
        </div>
        {editCashBudget ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateCashBudget();
            }}
            className="flex flex-col mb-4"
          >
            <input
              type="number"
              value={cashBudget}
              onChange={(e) => setCashBudget(e.target.value)}
              className="mb-2 p-2 border border-gray-300 rounded flex-grow dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
            />
            <button
              type="submit"
              className="bg-rose-600 mb-2 text-white px-4 py-2 rounded"
            >
              Update
            </button>
            <button
              onClick={() => setEditCashBudget(null)}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </form>
        ) : (
          <div className="flex justify-between items-center mb-1">
            <button
              className="flex items-end w-full justify-between text-left hover:cursor-pointer leading-snug"
              onClick={() => setEditCashBudget(true)}
            >
              <span className="inline-block truncate mr-1 dark:text-slate-400">Cash Budget</span>
              <span className="border-b flex-1 border-dashed border-slate-400 mb-1"></span>
              <span className="inline-block ml-1 text-rose-600 font-semibold">
                £{parseFloat(cashBudget || 0).toFixed(2)}
              </span>
            </button>
          </div>
        )}
      </div>
      <div className="mb-6">
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-xl font-semibold text-rose-600 mr-2">
            Fixed Expenses
          </h2>
          <button
            onClick={() => setShowAddExpenseForm((prev) => !prev)}
            className="text-rose-600 underline"
          >
            {showAddExpenseForm ? 'Cancel' : 'Add'}
          </button>
        </div>
        {showAddExpenseForm && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddExpense();
            }}
            className="flex flex-col mb-4 mt-2"
          >
            <input
              type="text"
              placeholder="Description"
              value={newExpense.description}
              onChange={(e) =>
                setNewExpense({ ...newExpense, description: e.target.value })
              }
              className="mb-2 p-2 border border-gray-300 rounded flex-grow dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
            />
            <input
              type="number"
              placeholder="Amount"
              value={newExpense.amount}
              onChange={(e) =>
                setNewExpense({ ...newExpense, amount: e.target.value })
              }
              className="mb-2 p-2 border border-gray-300 rounded flex-grow dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
            />
            <input
              type="text"
              placeholder="Category"
              value={newExpense.category}
              onChange={(e) =>
                setNewExpense({ ...newExpense, category: e.target.value })
              }
              className="mb-2 p-2 border border-gray-300 rounded flex-grow dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
            />
            {/* <label className="inline-block mr-2">
              <input
                type="checkbox"
                checked={newExpense.hide}
                onChange={(e) =>
                  setNewExpense({ ...newExpense, hide: e.target.checked })
                }
                className="mr-2"
              />
              Hide
            </label> */}
            <button
              type="submit"
              className="bg-rose-600 text-white px-4 py-2 rounded"
            >
              Add Expense
            </button>
          </form>
        )}
        <ul>{renderItems(expenses, 'expense')}</ul>
      </div>
      <div className="mb-6">
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-xl font-semibold text-lime-600 mr-2">
            Fixed Income
          </h2>
          <button
            onClick={() => setShowAddIncomeForm((prev) => !prev)}
            className="text-lime-600 underline"
          >
            {showAddIncomeForm ? 'Cancel' : 'Add'}
          </button>
        </div>
        {showAddIncomeForm && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddIncome();
            }}
            className="flex flex-col mb-4"
          >
            <input
              type="text"
              placeholder="Description"
              value={newIncome.description}
              onChange={(e) =>
                setNewIncome({ ...newIncome, description: e.target.value })
              }
              className="mb-2 p-2 border border-gray-300 rounded flex-grow dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
            />
            <input
              type="number"
              placeholder="Amount"
              value={newIncome.amount}
              onChange={(e) =>
                setNewIncome({ ...newIncome, amount: e.target.value })
              }
              className="mb-2 p-2 border border-gray-300 rounded flex-grow dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
            />
            <input
              type="text"
              placeholder="Category"
              value={newIncome.category}
              onChange={(e) =>
                setNewIncome({ ...newIncome, category: e.target.value })
              }
              className="mb-2 p-2 border border-gray-300 rounded flex-grow dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600"
            />
            {/* <label className="inline-block mr-2">
              <input
                type="checkbox"
                checked={newIncome.hide}
                onChange={(e) =>
                  setNewIncome({ ...newIncome, hide: e.target.checked })
                }
                className="mr-2"
              />
              Hide
            </label> */}
            <button
              type="submit"
              className="bg-lime-600 text-white px-4 py-2 rounded"
            >
              Add Income
            </button>
          </form>
        )}
        <ul>{renderItems(income, 'income')}</ul>
      </div>
      <button
        onClick={handlePopulateCurrentYear}
        className="bg-lime-600 text-white px-4 py-2 rounded"
      >
        Populate Current Year ({currentYear})
      </button>
    </div>
  );
};

export default Master;
