// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyD_22V5hZyRYzKTmrZdIBN77xqRUXOnHSk",
  authDomain: "budget-cf2f3.firebaseapp.com",
  databaseURL: "https://budget-cf2f3-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "budget-cf2f3",
  storageBucket: "budget-cf2f3.appspot.com",
  messagingSenderId: "492656007436",
  appId: "1:492656007436:web:4329e976eeaef4019ed129",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const firestore = getFirestore(app);

// Initialize Firebase Functions
const functions = getFunctions(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

export { firestore, functions, auth };
