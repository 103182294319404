// src/Login.js
import React from 'react';
import netlifyIdentity from 'netlify-identity-widget';
import { useAuth } from './AuthContext';
import { Navigate } from 'react-router-dom';

const Login = () => {
  const { currentUser } = useAuth();

  if (currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h2 className="text-2xl mb-4">Login</h2>
      <button
        onClick={() => netlifyIdentity.open()}
        className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
      >
        Login
      </button>
      <button
        onClick={() => netlifyIdentity.open('signup')}
        className="bg-green-500 text-white px-4 py-2 rounded"
      >
        Sign Up
      </button>
    </div>
  );
};

export default Login;
