// src/CurrentYearContext.js
import React, { createContext, useState, useEffect } from 'react';
import { firestore } from './firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const CurrentYearContext = createContext();

const CurrentYearProvider = ({ children }) => {
  const [currentYear, setCurrentYear] = useState('');

  useEffect(() => {
    const fetchCurrentYear = async () => {
      const settingsDoc = await getDoc(doc(firestore, 'settings', 'settings'));
      if (settingsDoc.exists()) {
        setCurrentYear(settingsDoc.data().current_year);
      }
    };
    fetchCurrentYear();
  }, []);

  const updateCurrentYear = async (year) => {
    const settingsRef = doc(firestore, 'settings', 'settings');
    await updateDoc(settingsRef, { current_year: year });
    setCurrentYear(year);
  };

  return (
    <CurrentYearContext.Provider value={{ currentYear, updateCurrentYear }}>
      {children}
    </CurrentYearContext.Provider>
  );
};

export { CurrentYearContext, CurrentYearProvider };
