import React, { createContext, useContext, useState, useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget';
import { useNavigate } from 'react-router-dom';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from './firebase';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const apiUrl = window.location.hostname === 'localhost'
      ? 'http://localhost:8888/.netlify/identity'
      : 'https://markcasey.uk/.netlify/identity';
    
    netlifyIdentity.init({ APIUrl: apiUrl });

    // Check for existing user session on load
    const user = netlifyIdentity.currentUser();
    if (user) {
      setCurrentUser(user);
      handleFirebaseAuth(user); // Authenticate with Firebase
    }

    const handleLogin = (user) => {
      setCurrentUser(user);
      netlifyIdentity.close(); // Close the widget after login
      handleFirebaseAuth(user); // Authenticate with Firebase
      navigate('/'); // Redirect after login
    };

    const handleLogout = () => {
      setCurrentUser(null);
      navigate('/login'); // Redirect after logout
    };

    netlifyIdentity.on('login', handleLogin);
    netlifyIdentity.on('logout', handleLogout);

    return () => {
      netlifyIdentity.off('login', handleLogin);
      netlifyIdentity.off('logout', handleLogout);
    };
  }, [navigate]);

  useEffect(() => {
    // Check token expiration and refresh if necessary
    const interval = setInterval(() => {
      const user = netlifyIdentity.currentUser();
      if (user && user.token && user.token.expires_at) {
        const expirationTime = new Date(user.token.expires_at);
        const currentTime = new Date();
        if (currentTime > expirationTime) {
          netlifyIdentity.refresh();
        }
      }
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  const handleFirebaseAuth = async (user) => {
    try {
      const userToken = user.token.access_token;

      const response = await fetch('/.netlify/functions/create-firebase-token', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching custom token: ${response.statusText}`);
      }

      const { firebaseToken } = await response.json();

      await signInWithCustomToken(auth, firebaseToken);
      console.log('Firebase authentication successful');
    } catch (error) {
      console.error('Error during Firebase authentication', error);
    }
  };

  const value = { currentUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
