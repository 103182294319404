// src/NavBar.js
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import netlifyIdentity from "netlify-identity-widget";
import { CurrentYearContext } from "./CurrentYearContext";

const NavBar = () => {
  const location = useLocation();
  const { currentYear } = useContext(CurrentYearContext);

  return (
    <nav id="top" className="bg-slate-900 py-2 px-2">
      <div className="max-w-md mx-auto">
        <div className="flex justify-between">
          <div className="text-white text-2xl font-bold">
            <Link to="/" className="">
              <img
                src="logo192.png"
                width="28px"
                height="28px"
                className="rounded"
                alt="Budget"
              />
            </Link>
          </div>
          <div className="text-white text-lg flex justify-end">
            <div className="text-white text-lg">
              <button
                onClick={() => netlifyIdentity.logout()}
                className="mr-4 text-slate-400"
              >
                Logout
              </button>
              <Link
                to="/settings"
                className={`mr-4 ${
                  location.pathname === "/settings"
                    ? "text-lime-500 font-semibold"
                    : ""
                }`}
              >
                Settings
              </Link>
              <Link
                to="/calculator"
                className={`mr-4 ${
                  location.pathname === "/calculator"
                    ? "text-lime-500 font-semibold"
                    : ""
                }`}
              >
                Calculator
              </Link>
              <Link
                to="/master"
                className={`mr-4 ${
                  location.pathname === "/master"
                    ? "text-lime-500 font-semibold"
                    : ""
                }`}
              >
                Master
              </Link>
              <Link
                to="/"
                className={`${
                  location.pathname === "/" ? "text-lime-500 font-semibold" : ""
                }`}
              >
                {currentYear ? `${currentYear}` : "Budget"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
